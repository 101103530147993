.members__table-row td:first-of-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.members__table-row td:first-of-type div {
  margin: 0;
  margin-right: 5%;
}

/* .member-delete-icon {
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: grab;
}
.member-edit-icon {
    position: absolute;
    top: 30px;
    right: 50px;
    cursor: grab;
} */
/* .article-modal__content-input{
    display: flex;
    justify-content: space-between;
}
.article-modal__content-input > div{
    width: 96%;
    margin: 1.2rem 1rem;

}
.article-modal__uploaded-files > div{
    display: flex;
}
.article-modal__uploaded-files{
    color:var(--color-secondary);
    font-family: SFProDisplay-Regular;
    font-size: 1.5rem;
    padding-left: 1rem;
    margin-bottom: 3rem;
}
.article-modal__uploaded-files p{
    text-align: left;
}
.article-modal__uploaded-files span{
    color: rgba(102, 126, 128, 0.35);
    font-size: 1.1rem;
    margin-top: 1.3rem;
} */

.MuiTablePagination-spacer {
  display: none;
}
.MuiTablePagination-toolbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.MuiTableCell-body {
  border-bottom: none !important;
  font-size: 1rem !important;
  color: #667e80 !important;
}
.MuiTableCell-head {
  padding-right: 23px !important;
  font-size: 0.95rem !important;
  color: #a6b9ba !important;
}
.members__table td > div > svg {
  height: 2rem;
  width: auto;
  margin: 0 0.2rem;
  cursor: pointer;
}

.members__table-row {
  cursor: pointer;
  background-color: rgb(251 249 249 / 54%);
  border-left: solid 0.563rem transparent !important;

  &:hover {
    border-left: solid 0.563rem var(--color-secondary) !important;
  }
}

.members__table-row:hover > th,
.members__table-row:hover > td {
  color: var(--color-hover) !important;
}

.member-edit__image--upload {
  width: 40%;
  margin: 0px;
  border-width: 0.156rem;
  margin-bottom: 1rem;
  padding: 3.469rem 0.875rem;
}
.member-edit__image--upload > svg {
  width: 7rem;
  margin-right: 0rem;
}
.edit-upload-file__label-text {
  font-family: SFProDisplay-Regular;
  font-size: 1.1rem;
  color: var(--color-secondary);
  /* text-align: center; */
  cursor: pointer;
  display: block;
}
.memberm-modal-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 1px solid #a6b9ba;
  border-radius: 4px;
  height: 40px;
  margin-bottom: 10px;
  display: block;
  min-width: 0;
  background: none;
  animation-name: mui-auto-fill-Abbrechen;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  padding-left: 15px;
}

.memberm-modal-input:focus {
  outline: none;
}
.members__batch-upload--button {
  height: 2.5rem!important;
  color: #fff !important;
  background-color: var(--color-primary) !important;
  width: 11.813rem!important;
  text-transform: unset !important;
  font-family: SFProDisplay-Regular !important;
  font-size: 1.03rem !important;
  span{
    display: flex;
    align-items: center;
  }

  svg{
    width: 0.85em;
    height: 0.85em;
  }
}
.members__batch-upload--button:hover {
  border: solid 1.5px var(--color-primary) !important;
  color: var(--color-primary) !important;
  background-color: #fff !important;
}
.members__search {
  width: 76%;
}
