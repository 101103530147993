.member-details-header {
  display: flex;
  align-items: center;
  color: var(--color-secondary);
}
.member-details-header .previous-link:hover {
  color: var(--color-secondary);
}
.member-details-card {
  padding: 20px;
  margin: 50px 30px 25px 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #667e80;
}
.member-details-card-body {
  padding-left: 40px;
}
.member-details-card-body > div:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.35em;
  /* border-bottom: 1px solid #667e80; */
}
.member-details-card-body > div:nth-of-type(1) > div {
  display: flex;
  align-items: center;
}

.MuiBadge-root {
  padding-left: 40px;
}

.member-profile-foto {
  text-align: center;
}
.member-profile-foto div img {
  width: 100%;
}
/* .member-profile-foto div {
    width: 200px;
} */
.MuiAvatar-root {
  margin-left: auto;
  margin-right: auto;
}
.member-details-edit-icon {
  position: static;
  cursor: pointer;
}

.previous-link {
  color: var(--color-secondary);
}

@media (max-width: 768px) {
  .member-details-card-body {
    margin-top: 20px;
    padding-left: 0;
  }
  .MuiAvatar-root {
    margin-left: auto;
    margin-right: auto;
  }
}
