.positions__select-department .css-1pahdxg-control {
  box-shadow: 0 0 0 1px var(--color-secondary) !important;
  border-color: var(--color-secondary) !important;
}
.positions__select-department .css-1gtu0rj-indicatorContainer {
  color: var(--color-secondary) !important;
}
.positions__select-department .css-26l3qy-menu {
  box-shadow: 0 0 0 1px var(--color-secondary), 0 4px 11px hsl(0deg 0% 0% / 10%) !important;
}
.css-1wa3eu0-placeholder {
  font-family: SFProDisplay-Regular;
  font-size: 1.05rem;
  color: #b9b9b9 !important;
}
.positions__select-department .css-g1d714-ValueContainer,
.css-1hb7zxy-IndicatorsContainer {
  height: 100%;

  .css-b8ldur-Input{
    height: 100%;

    div{
      height: 100%;

      input{
        height: 100%;
        margin-bottom: 0;
      }
    }
  }
}

.positions__select-department .css-yk16xz-control,
.positions__select-department .css-1pahdxg-control {
  border-radius: 0.625rem;
  height: 3.125rem !important;
  min-height: 3.125rem !important;

  .css-1hwfws3{
    height: 100%;
  }
}
.positions__select-department {
  width: 100%;
  margin-bottom: 1.5rem;
}

/*DEPARTMENT DROPDOWN*/
.department__dropdown {
  margin-bottom: 1rem;
}
.department__dropdown label {
  margin-right: 1.5rem;
}
.gt label {
  margin-right: 3.5rem;
}
