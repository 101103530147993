.inputform1{
    border-radius: 25px 25px;
    background-color: #efefef;
    border: none;
    height: 56px;
    width: 432px;
    padding-left: 15px;
    font-size: 20px;
    
}

.inputform1:focus{
    outline: none;

    
}