.task-details__content > p {
  color: #667e80;
  font-size: 1rem;
  font-family: SFProText-Regular;
}
.task-details__content p span {
  font-family: SFProText-Semibold;
}
.task-details__content-input {
  display: flex;
  justify-content: space-between;
}
.task-details__content-input > div {
  width: 100%;
  margin-bottom: 20px;
}

.task-details__btn > button {
  min-width: 8.175rem !important;
  width: auto !important;
  height: 2.5rem !important;
  border-radius: 0.625rem !important;
}
.task-details__btn > button > span {
  font-family: SFProText-Bold !important;
  font-size: 1.1rem !important;
}

.task-details__done {
  float: right;
}

.task-details__approve > button {
  width: 14.175rem !important;
  float: right !important;
}

.task-details__reject > button {
  float: left !important;
  margin-left: 0rem !important;
}

.task-details__nocomment {
  color: rgba(102, 126, 128, 0.35);
  font-size: 1.1rem;
}

.task-details__comment > h5 {
  color: #667e80 !important;
  font-family: SFProText-Regular !important;
}

.task-details__comment--user {
  margin: 0px;
  text-align: left;
  font-family: SFProText-Semibold !important;
  color: #667e80 !important;
  font-size: 1rem !important;
}

.process__details--comments .MuiGrid-root > p {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  margin-bottom: 0px;
  text-align: left;
  font-family: unset;
  color: unset;
}
