.MuiBackdrop-root {
  background-color: #e9eef080 !important;
}
.MuiDialog-paperScrollPaper {
  box-shadow: 0 5px 10px 0 rgba(150, 150, 150, 0.25) !important;
}
.dialog .MuiDialog-paperScrollPaper {
  width: 50%;
  border-radius: 1.8rem !important;
  border-left: 32px solid var(--color-secondary);
  overflow-y: visible;
}
#dialog-title > h2,
.dialog p {
  font-family: SFProText-Bold;
  font-size: 1.5rem;
  color: var(--color-secondary);
}
.dialog input {
  border-radius: 0.625rem;
  border: solid 1px #cfcfcf;
  width: 100%;
  height: 3.125rem;
  outline: none;
  padding-left: 0.6rem;
  color: #535353;
  font-size: 1rem;
  margin-bottom: 10px;
}
.dialog input::placeholder {
  font-family: SFProDisplay-Regular;
  font-size: 1.05rem;
  color: #b9b9b9;
}
#dialog-description {
  margin-bottom: 12px;
  overflow-y: visible;
}
.dialog p {
  text-align: center;
}
.dialog select {
  border-radius: 0.625rem;
  border: solid 1px #cfcfcf;
  width: 90%;
  height: 3.125rem;
  outline: none;
  padding-left: 0.6rem;
  color: #535353;
  font-size: 1rem;
  margin-bottom: 10px;
}
.dialog section .opt {
  border-radius: 0.625rem;
  border: solid 1px #cfcfcf;
  width: 90%;
  height: 3.125rem;
  outline: none;
  padding-left: 0.6rem;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #535353;
  font-size: 3rem;
}
