.forgotPassword__form > div:nth-of-type(1) {
  margin-bottom: 1.563rem;
}
.forgotPassword__form > p {
  font-family: SFProDisplay-Regular;
  font-size: 1.375rem;
  color: #828282;
  text-align: center;
}
.forgotPassword__button {
  margin-top: 8.75rem;
}
.forgotPassword__secondPage > p:nth-of-type(1) {
  margin-top: 4em;
  font-size: 1.125rem;
}
.forgotPassword__secondPage > p:nth-of-type(1) > span {
  color: #828282;
}
.forgotPassword__secondPage--button {
  margin-top: 5.62rem;
}
.resetPassword__secondPage > p {
  display: flex;
  flex-direction: column;
  font-family: SFProDisplay-Regular;
  color: #828282;
  font-size: 1.125rem;
}

.f-linkLabel a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
}

.f-linkLabel a:hover {
  color: var(--color-hover);
  text-decoration: underline;
}
