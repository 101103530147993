* {
  box-sizing: border-box;
}
.dashboard-statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 40px; */
  margin-top: 40px;
  /* margin-left: 10px; */
}
.statistics-wrapper {
  /* background-color: #F3F3F3; */
  padding: 40px 5%;
}
.dashboard-statistics .dashboard-statistics-content {
  flex: 1 1 0;
  padding: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 20px;
  color: #fff;
  border-radius: 5px;
}

.dashboard-statistics .dashboard-statistics-content:nth-of-type(1) {
  background-color: #ffab2c;
  transition: all 0.7s;
  margin-left: 0;
}
.dashboard-statistics .dashboard-statistics-content:nth-of-type(1):hover {
  background-color: #d17d00;
  /* padding-bottom: 60px; */
}

.dashboard-statistics .dashboard-statistics-content:nth-of-type(2) {
  background-color: var(--color-hover);
  transition: all 0.7s;
}
.dashboard-statistics .dashboard-statistics-content:nth-of-type(2):hover {
  background-color: #0078bd;
  /* padding-bottom: 60px; */
}

.dashboard-statistics .dashboard-statistics-content:nth-of-type(3) {
  background-color: #5fc682;
  transition: all 0.7s;
}
.dashboard-statistics .dashboard-statistics-content:nth-of-type(3):hover {
  background-color: #00a537;
  /* padding-bottom: 60px; */
}
.dashboard-news-wrapper {
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

.slick-slide {
  /* width: 50%; */
  margin: 5px 0;
}
.slick-list {
  /* width: 50%; */
  padding: 0px 4px !important;
}
.slick-prev,
.slick-next {
  position: absolute !important;
  top: 50% !important;
  display: block !important;
  width: 20px !important;
  height: 20px !important;
  transform: translate(0, -50%) !important;
  /* border: solid 1px #000 !important; */
}
.slick-prev {
  left: -75px !important;
}
.slick-next {
  right: 0px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 50px !important;
  line-height: 1;
  opacity: 0.75;
  color: #aaa !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.course-slider {
  /* width: 75%; */
  position: relative;
  margin-top: 40px;
  /* background-color: #F3F3F3; */
  /* padding: 0px 1%; */
}
.article-slider {
  /* width: 75%; */
  position: relative;
  /* margin-top: 40px; */
  /* background-color: #F3F3F3; */
  /* padding: 0px 1%; */
}
.slider-wrapper {
  width: 60%;
}

.all-articles-wrapper {
  position: absolute;
  top: 10px;
  right: 3%;
}
.all-articles-link {
  font-size: 1.2rem;
  padding: 5px 5px;
  font-weight: bold;
  color: var(--color-secondary);
  /* border: solid 1px #eee; */
  /* border-radius: 40px; */
  cursor: pointer;
}
.all-articles-link:hover {
  text-decoration: underline;
}

.MuiCardActions-root {
  display: flex;
  padding: 4px 8px !important;
  align-items: center;
}
.MuiCardHeader-root {
  display: flex;
  padding: 8px 16px !important;
  align-items: center;
}

.MuiCardContent-root {
  padding: 8px 16px !important;
}
.article-card {
  width: 90%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-start; */
  /* margin: 0 30px !important; */
  /* margin: 10px; */
  /* border: solid 1.5px #ccc !important;
     padding: 5px; */
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  flex: 300px;
  height: 540px;
}

/* .article-card:hover{
      box-shadow: 0 0 25px 5px #ccc;
} */

.article-card .article-card-content {
  min-height: 50px;
  /* max-height: 200px; */
  /* overflow: hidden; */
  padding: 0 5%;
}
.article-card-img-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.article-card-img-wrapper img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.article-card-details-wrapper {
  margin-bottom: 10px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article-card-header-wrapper {
  height: auto;
  margin-bottom: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0 5%;
}

.article-card-content {
  margin-bottom: 20px;
  font-size: 1rem;
}

.article-card-date {
  margin: 0;
  margin-top: -10px;
  padding: 0;
  font-size: 10px;
}
.article-card-author {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.article-card-symbol {
  color: #fff;
  margin: 0;
  padding: 0px 30px;
  font-size: 12px;
  background-color: #ff9900;
  border-radius: 3px;
}

.course-card-symbol {
  color: #fff;
  margin: 0;
  padding: 0px 30px;
  font-size: 12px;
  background-color: var(--color-hover);
  border-radius: 3px;
}
.article-card-readtime {
  padding: 0 5%;
  font-size: 10px;
}

.article-card-footer {
  /* margin: 30px 0 0px; */
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #00000085;
  /* color: var(--color-secondary); */
  /* font-weight: bold; */
  padding: 10px 5%;
}

/* .article-card-info {
     padding: 10px 0 !important;
     color: black !important;
}
.article-card-info p,.article-card-info svg,.article-card-info a,.article-card-info img,.article-card-info span,.article-card-info div  {
     color: black !important;
} */
@media (max-width: 1545px) {
  .dashboard-statistics .dashboard-statistics-content {
    padding: 30px;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 3rem;
  }
  .makeStyles-statType-10 {
    font-size: 3rem;
  }
}

@media (max-width: 1300px) {
  .dashboard-statistics .dashboard-statistics-content {
    padding: 20px;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 2rem;
  }
  .makeStyles-statType-10 {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .dashboard-statistics .dashboard-statistics-content {
    padding: 10px;
  }
  .MuiSvgIcon-fontSizeLarge {
    font-size: 1rem;
  }
  .makeStyles-statType-10 {
    font-size: 1rem;
  }
}
@media (max-width: 1000px) {
  .dashboard-statistics .dashboard-statistics-content {
    padding: 40px;
    width: 80%;
    margin-top: 20px;
  }
  .dashboard-statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
  }
  .dashboard-statistics .dashboard-statistics-content {
    border-right: none;
    width: 90%;
    margin-left: 20px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px !important;
  }
  .slick-prev {
    left: -35px !important;
    width: 20px !important;
    height: 20px !important;
  }
  .slick-next {
    right: 15px !important;
    width: 20px !important;
    height: 20px !important;
  }
}

@media (max-width: 500px) {
  .dashboard-statistics .dashboard-statistics-content {
    padding: 40px 10px;
    font-size: 10px !important;
    margin-left: 20px !important;
  }
}
