@media only screen and (max-width: 1660px) and (max-height:865px){
    .login__button {
        margin-top: 8.5rem;
    }
    .forgotPassword__secondPage--button{
        margin-top: 2.5rem!important;
    }
}
@media only screen and (max-width:1290px){
    .login__right {
        width: 40rem;
    }
    .login__left {
        width: calc( 100% - 40rem);
    }
}
@media only screen and (max-width:1366px) and (max-height:850px){
    html {
        font-size: 86%;
    }
    .login__right {
        width: 48rem;
    }
    .login__left {
        width: calc( 100% - 48rem);
    }
    .popup-menu > svg {
        top: -25px;
    }
    .popup-menu__popper {
        top: 17px!important;
    }
}
@media only screen and (max-width:1400px) and (max-height:780px){
    html {
        font-size: 80%;
    }
    .login__left {
        width: calc( 100% - 53rem);
    }
    .login__right {
        width: 53rem;
    }
    .login__right h2 {
        margin-top: 0rem;
        font-size: 2rem;
    }
    .login__form > div:nth-of-type(1) {
        margin-bottom: 1.8rem;
    }
    .login__form {
        margin-top:9.513rem!important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(12px, -6px) scale(0.86)!important;
    }
    .register__thirdPage--bottom > p:nth-of-type(1) {
        margin-bottom: 1.125rem;
        margin-top: 2.25rem;
    }
    .thirdPage__button {
        margin-top: 3.375rem;
    }
    .emailVerified__button {
        margin-top: 1.75rem;
    }
    .forgotPassword__button{
        margin-top: 3.5rem!important;
    }
    .forgotPassword__secondPage > p:nth-of-type(1) {
        margin-top: 2em;
    }
    .forgotPassword__secondPage > h2{
        margin-bottom: 1.5rem!important;
    }
    .forgotPassword__secondPage--button{
        margin-top: 2.5rem!important;
    }
    .register__button {
        margin-top: 6.37rem;
    }
    .login__button {
        margin-top: 4.5rem;
    }
    .register__form--firstPage{
        margin-top: 3.813rem!important;
    }
}
@media only screen and (max-width:1120px), (max-width:1024px) and (min-height:1050px){
        .login{
            position: relative;
           justify-content: center;
        }
        .login__left{
            width: 100%;
        }
        .login__right {
            width: 36rem;
            position: absolute;
        }
        .login__left--icons {
            padding-right: 0.6rem;
            padding-bottom: 0.9rem;
        }
        .icon__facebook {
            height: 30px;
        }
        .icon__instagram {
            height: 34px;
            margin-left: 0.5em;
        }
}
@media only screen and (max-width:850px){
    .departments__top--serach{
        flex-direction: column-reverse;
        height: 6.5rem;
    }
    .departments__add-new--button{
        justify-self: flex-end;
    }
    .search{
        width: 90%;
    }
    .sidebar{
        position: fixed;
        z-index: 1;
        height: 100vh;
    }
    .page__body{
        margin-left: 6rem;
        margin-right: 0.4rem;
  }
}

@media only screen and (max-width:750px){
    html{
        font-size: 100%;
    }
    .login__left--icons {
        display: none;
    }
    .login__left--iconsMobile {
        display: flex;
    }
    .login__right {
        width: 100%;
    }
    .login__right--content {
        width: 100%;
        padding-left: 65px;
        padding-right: 65px;
        padding-top: 0;
    }
    .login__right h2 {
        margin-top: 0.188rem;
    }
    .login__form {
        margin-top: 2.5rem!important;
    }
    .login__button {
        margin-top: 4.5rem!important;
    }
    .register__button {
        margin-top: 1.37rem!important;
    }
    .alert > div{
        margin-top: -2.875rem!important;
    }
    .register__error > div{
        margin-top: -3.875rem!important;

    }
    .emailVerified__button {
        margin-bottom: 1.5rem;
    }
    .forgotPassword__form{
        margin-top: 7.5rem!important;
    }
    .forgotPassword__error > div{
        margin-top: 1.8rem!important;
    }
    .forgotPassword__first--heading{
        margin-bottom: 2rem!important;
    }
    .forgotPassword__button {
        margin-top: 1.5rem!important;
    }
    .forgotPassword__secondPage > p:nth-of-type(1) {
        margin-top: 1em;
    }
    .forgotPassword__secondPage--button {
        margin-top: 1rem!important;
        margin-bottom: 0rem;
    }
    .popup-menu > svg {
        top: -29px;
    }
    .popup-menu__popper {
        top: 22px!important;
    }

    .MuiTableContainer-root > table{
        overflow-x: scroll;
        min-width: 469px;
    }
    .page__body{
        /* padding:2rem 0.5rem; */
        min-height: 875px;
        margin-left: 5rem;
    }
}
@media only screen and (max-width:670px){
    .page__body {
        width: 90%;
    }
    .departments__top--serach > div {
        width: 100%;
    }
    .departments-search__button {
        min-width: 5.875rem!important;
    }
    .departments__top--serach > div > input
    {
        min-width: unset;
    }
    .header__right{
        width:21rem;
    }
}
@media only screen and (max-width:500px){
    .MuiTablePagination-toolbar{
        flex-direction: column-reverse;
       padding: 0px;
    }
    .MuiTablePagination-toolbar > p{
    align-self: flex-end;
    }
    .MuiTablePagination-toolbar > nav{
        align-self: flex-start;
    }
    .department__dropdown, .sort{
        flex-direction: column;
       align-items: flex-start;
    }
    .department__dropdown label, .sort label{
        margin-bottom: 0.6rem;
    }
    .sort-input{
        width: 100%;
    }
    .makeStyles-drawerClose-4{
        width:0px!important;
    }
    .page__body {
        width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .MuiToolbar-gutters{
        padding:0px!important;
    }
    .table__navigation{
        width:82%;
    }
    .login__right {
        height: auto;
    }
}

@media only screen and (max-width:395px){
    .login__right--img{
        width: 80%!important;
        height: auto!important;
    }
    .login__button {
        width: 75%;
    }
    .login__button button{
        width:100%;
    }
    .login__right--content > p{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .login__right--content > p > a{
        margin-left: 0;
    }
    .icon__instagram {
        height: 30px;
        margin-left: 0em;
    }
    .icon__facebook {
        height: 26px;
    }
    .login__right--content {
        padding-left: 30px;
        padding-right: 30px;
    }
    .alert > div{
        width:100%;
    }
    .forgotPassword__error > div {
        margin-top: 1.125rem!important;
    }
    .header__right {
        width: 18rem;
        height: 25%;
    }
    .header__right svg {
        height: 100%;
        width: auto;
    }
    .header__right-user .MuiAvatar-root {
        width: 30px;
        height: 30px;
    }
    .header__right .MuiBadge-root{
        height: 100%;
    }
    /* .makeStyles-drawerClose-4 {
        width: 66px!important;
    } */
    .makeStyles-drawerOpen-3 {
        width: 16.063rem!important;
    }
    .sidebar__menu-button {
        padding-left: 1rem;
    }
    .sidebar__menu-list > a, .sidebar__menu-list > .sidebar__menu-organization {
        padding-left: 22px;
    }
    .popup-menu {
        transform: scale(0.8,0.8)!important;
    }
    .popup-menu__popper {
        left: 43px!important;
    }
    .body__content{
        padding:2.813rem 1rem;
    }
    .page__body{
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}


@media only screen and (min-width: 1680px){
    .login__right {
        width: 52rem;
    }
    .login__left {
        width: calc( 100% - 52rem);
    }
}
@media only screen and (min-width: 1780px){
    .login__right {
        width: 55rem;
    }
    .login__left {
        width: calc( 100% - 55rem);
    }
}
@media only screen and (min-width: 1920px){
    .login__right {
        width: 58rem;
    }
    .login__left {
        width: calc( 100% - 58rem);
    }
}
@media only screen and (min-width: 2300px){
    .login__right {
        width: 61rem;
    }
    .login__left {
        width: calc( 100% - 61rem);
    }
}