.popupbox8 {
  border: 1px solid #f2f2f2;
  border-radius: 25px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  height: 500px;
  background-color: #efefef;
  padding: 20px;
  overflow: hidden;
}

.title2 {
  font-size: 34px;
}
.popuptxt {
  font-size: 21px;
  overflow-y: auto;
}
.holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  height: 100vh;
  opacity: 90%;
  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}
.popbtn {
  display: flex;
  width: 195px;
  height: 30px;
  background-color: var(--color-secondary);
  border: 1px solid #f2f2f2;
  border-radius: 25px;
  text-align: center;
  justify-content: center;
  color: white;
}
.btndiv {
  padding-top: 20px;
  display: flex;
  width: 50%;
  justify-content: end;
}
