.register__form--firstPage {
  margin-top: 2.063rem;
}
.register__form--secondPage {
  margin-top: 7.5rem;
}
.register__form > div {
  margin-bottom: 1.5rem !important;
}
.register__button {
  margin-top: 3rem;
}
.register__button--firstPage > button {
  width: 12.188rem;
}
.register__right--second > h2 {
  margin-top: 6.25rem;
}
.register__form--secondPage > p:nth-of-type(1) {
  color: var(--color-secondary);
  font-family: SFProDisplay-Regular;
  font-size: 1.63rem;
  margin-bottom: 2.188rem;
}
.register__thirdPage--bottom > p {
  font-family: SFProDisplay-Regular;
  color: #535353;
  text-align: center;
}
.register__thirdPage--bottom > p:nth-of-type(1) {
  font-size: 1.563rem;
  margin-top: 4.25rem;
  margin-bottom: 1.125rem;
}
.register__thirdPage--bottom > p:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.register__thirdPage--bottom > p:nth-of-type(2) > span {
  font-size: 1.23rem;
}
.register__thirdPage--bottom > p:nth-of-type(2) > a {
  color: var(--color-secondary);
  font-size: 1.23rem;
  margin-left: 0.2em;
  cursor: pointer;
}
.thirdPage__button {
  margin-top: 5.375rem;
}
.emailVerified__button {
  margin-top: 3.75rem;
}
.icon__back {
  position: absolute;
  top: 2.13rem;
  left: -4.5rem;
  fill: #d9d9d9 !important;
  width: 2.3em !important;
  height: auto !important;
  cursor: pointer;
}
