.MuiSelect-select:focus {
  background-color: transparent !important;
}
.dropdown-select svg {
  width: 2.5rem;
  height: auto;
  top: calc(50% - 19px);
  fill: #cfcfcf;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--color-primary) !important;
}
.dropdown__nothing-found {
  padding: 10px 25px;
  font-size: 1.1rem;
  font-family: "SFProText-Regular";
  color: #9eadb3;
  pointer-events: none;
}

.div-click-create {
  padding: 10px 25px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: var(--color-primary);
  cursor: pointer;
}
.div-click-create:hover {
  /* background-color: #00000010; */
  text-decoration: underline;
  transition: all 0.5s;
}

.textfield-wrapper button {
  padding: 0 !important;
  margin-left: 12px;
}

.textfield-wrapper .MuiSvgIcon-root {
  width: 36px !important;
  height: 36px !important;
  /* fill: #354B69; */
}
.MuiFormLabel-root.Mui-focused {
  color: var(--color-primary) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-primary) !important;
}
