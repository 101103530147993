.upload-profile-modal .MuiDialog-paper {
  width: 30%;
  height: auto;
}
.upload-btn {
  display: block;
  margin: auto !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  width: 10.175rem !important;
}
