.breadcrumbs__nav {
  font-size: 1.5rem !important;
  color: var(--color-secondary);
  font-family: SFProText-Semibold !important;
}
.breadcrumbs__nav--last {
  color: #c4c4c4;
  font-size: 1.5rem !important;
  font-family: SFProText-Semibold !important;
}
