* {
  box-sizing: border-box;
}

.knowledge-slider-wrapper {
  display: flex !important;
  /* flex-wrap: nowrap; */
  justify-content: space-between !important;
  padding: 0 4rem;
}
.knowledge-favourites-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 37px;
}
.knowledge-later-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 35px;
  margin-top: 47px;
}

.right-side-component {
  /* width: 30%; */
  /* margin-left: 40px;  */
}
.slider-wrapper {
  width: 65%;
}
.knowledge-favourites-wrapper {
  /* width: 65%; */
  display: grid;
  grid-template-columns: repeat(2, 240px);
  grid-gap: 40px;
  grid-auto-rows: minmax(100px, auto);
}

.article-card-klein {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-start; */
  /* margin: 0 30px !important; */
  /* margin: 10px; */
  /* border: solid 1.5px #ccc !important;
     padding: 5px; */
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  flex: 200px;
  height: 250px;
}

.knowledge-favourites-heading-wrapper .all-articles-wrapper,
.knowledge-later-heading-wrapper .all-articles-wrapper {
  position: static;
}

.knowledge-favourites-heading-wrapper .all-articles-link {
  font-size: 1.2rem;
  padding: 5px 5px;
  font-weight: bold;
  color: var(--color-secondary);
  /* border: solid 1px #eee; */
  /* border-radius: 40px; */
  cursor: pointer;
}
.knowledge-favourites-heading-wrapper .all-articles-link:hover {
  text-decoration: underline;
}

.article-card-klein .article-card-content {
  /* min-height: 50px; */
  /* max-height: 200px; */
  /* overflow: hidden; */
  padding: 0 5%;
}
.article-card-klein .article-card-img-wrapper {
  width: 100%;
  margin-bottom: 1px;
}
.article-card-klein .article-card-img-wrapper img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.article-card-klein .article-card-details-wrapper {
  margin-bottom: 1px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article-card-klein .article-card-header-wrapper {
  /* height: auto; */
  margin-bottom: 0px;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 5%;
  line-height: 14px;
}

.article-card-klein .article-card-content {
  margin-bottom: 1px;
  font-size: 0.6rem;
  line-height: 12px;
}

.article-card-klein .article-card-date {
  margin: 0;
  margin-top: -10px;
  padding: 0;
  font-size: 10px;
}
.article-card-klein .article-card-author {
  margin: 0;
  padding: 0;
  font-size: 10px;
}
.article-card-klein .article-card-symbol {
  color: #fff;
  margin: 0;
  padding: 0px 10px;
  font-size: 10px;
  background-color: #ff9900;
}
.article-card-klein .article-card-readtime {
  padding: 0 5%;
  font-size: 9px;
}

.article-card-klein .article-card-footer {
  /* margin: 30px 0 0px; */
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #00000085;
  /* color: var(--color-secondary); */
  /* font-weight: bold; */
  padding: 0px 5% 5px;
}
