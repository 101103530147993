.departments__top {
  margin-top: 2.188rem;
  margin-bottom: 3.438rem;
}
.departments__top--serach {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.departments__add-new--button {
  height: 2.5rem!important;
  color: #fff !important;
  background-color: var(--color-primary) !important;
  width: 7.9rem;
  text-transform: unset !important;
  font-family: SFProDisplay-Regular !important;
  font-size: 1.03rem !important;
  padding: 6px 10px!important;
  span{
    display: flex;
    align-items: center;
  }

  svg{
    width: 0.85em;
    height: 0.85em;
  }
}

.departments__add-new--button:hover {
  border: solid 1.5px var(--color-primary) !important;
  color: var(--color-primary) !important;
  background-color: #fff !important;
}
.departments__modal--buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.125rem;
}
.departments__modal--add {
  margin-left: 4.375rem;
}
.departments__modal--add > button {
  min-width: 8.175rem !important;
  width: auto !important;
  height: 2.5rem !important;
  border-radius: 0.625rem !important;
}
.departments__modal--add > button > span {
  font-family: SFProText-Bold !important;
  font-size: 1.1rem !important;
}

/*TABLE*/
.MuiTablePagination-spacer {
  display: none;
}
.MuiTablePagination-toolbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.MuiTableCell-body {
  border-bottom: none !important;
  font-size: 1rem !important;
  color: #667e80 !important;
}
.MuiTableCell-head {
  padding-right: 23px !important;
  font-size: 0.95rem !important;
  color: #a6b9ba !important;
}
.departments__table td > div > svg {
  height: 2rem;
  width: auto;
  margin: 0 0.2rem;
  cursor: pointer;
}
.departments__table-row {
  cursor: pointer;
  background-color: rgb(251 249 249 / 54%);
  border-left: solid 0.563rem transparent !important;

  &:hover {
    border-left: solid 0.563rem var(--color-secondary) !important;
  }
}
.departments__table-row:hover > th,
.departments__table-row:hover > td {
  color: var(--color-hover) !important;
}
/*DEPARTMENT MEMBERS*/
.department-members .MuiDialog-paper {
  padding: 1.5rem 2.3rem;
  border-radius: 0.625rem;
  width: 80%;
  max-width: 53.125rem;
}
.department-members__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.department-members__numbers {
  font-family: SFProText-Regular;
  font-size: 1rem;
  color: #c4c4c4;
  margin-bottom: 2.188rem;
}
.department-members__list {
  margin-bottom: 4.8rem;
}
.department-members__single {
  display: flex;
  margin-bottom: 2.625rem;
}
.members-single__img {
  width: 5rem;
  height: 5.188rem;
  border-radius: 0.9rem;
  background-color: rgb(180, 178, 178);
  margin-right: 0.75rem;
}
.members-single__img > img {
  width: 100%;
  height: 100%;
  border-radius: 0.9rem;
  object-fit: cover;
}
.members-single__content {
  height: 5.188rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.members-single__content > span {
  color: #c5c5c5;
  font-family: SFProText-Regular;
  font-size: 0.9rem;
}
.members-single__content > span:nth-of-type(1) {
  color: #a88a8a;
  font-family: SFProText-Semibold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.members-single__tooltip {
  border-radius: 50%;
  background-color: #4aaee7;
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed !important;
}
