.sort {
  display: flex;
  align-items: center;
}
.sort > label {
  font-size: 1rem;
  color: #595959;
  font-family: SFProDisplay-Regular;
  margin-right: 1.8rem;
}
.sort-input {
  width: 18rem;
}

/*SELECT DROPDOWN*/
.css-1pahdxg-control {
  box-shadow: 0 0 0 1px var(--color-hover) !important;
  border-color: var(--color-hover) !important;
}
.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 2.25rem !important;
  height: 2.25rem !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 4px 8px !important;
}
.css-1pahdxg-control:hover {
  border-color: #e2e2e2 !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.css-9gakcf-option,
.css-1n7v3ny-option {
  background-color: #f1f1f1 !important;
  padding: 0.313rem 0.75rem !important;
  color: #535353 !important;
  font-family: SFProDisplay-Regular !important;
}
.css-yt9ioa-option {
  padding: 0.313rem 0.75rem !important;
  color: #535353 !important;
  font-family: SFProDisplay-Regular !important;
}
.css-1uccc91-singleValue {
  font-family: SFProDisplay-Regular !important;
}
.css-9gakcf-option {
  background-color: #d0cece !important;
}
.css-26l3qy-menu {
  box-shadow: 0 0 0 1px var(--color-hover), 0 4px 11px hsl(0deg 0% 0% / 10%) !important;
  margin-top: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  height: 9rem;
  overflow-y: auto;
}
.css-4ljt47-MenuList {
  max-height: 9rem !important;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

  /* Scroll Bar */
  .css-26l3qy-menu::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }
  .css-26l3qy-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  .css-26l3qy-menu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  .css-26l3qy-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }