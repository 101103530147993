.MuiInputBase-multiline {
  /* border: 1px solid; */
  border-radius: 10px;
  padding: 10px !important;
  border-color: #cfcfcf;
}

.MuiButtonBase-root.Mui-disabled {
  pointer-events: auto !important;
}
.css-133v6e1-option {
  color: #000 !important;
  background-color: #cccccc !important;
}

.css-165luzk-option {
  cursor: not-allowed !important;
}

.process-continue {
  float: right !important;
}

.task-action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-toggle .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px !important;
}
