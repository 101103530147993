* {
  columns: row;
  flex-direction: row;
}
.yes {
  border: 1px solid #fc0202;
}
.first {
  position: relative;
  bottom: 10px;

  margin-right: 4px;
  font-style: white;
  border-color: solid 2px white;
  background-color: rgba(20, 17, 17, 0.822);
  border-radius: 15px;
  color: white;
  padding: 6px;
  font-size: 12px;
}
.userphoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.second {
  position: relative;
  bottom: 4px;
  margin-right: 4px;
  font-style: white;
  border-color: solid 2px white;
  background-color: #eee;
  border-radius: 15px;
  color: black;
  padding: 8px;
  font-size: 12px;
}
