.rbc-event {
  background-color: var(--color-secondary) !important;
  border-radius: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.rbc-today {
  /* background-color: #9afaff !important;  */
}

/* .rbc-month-header {
      padding-top: 50 !important;
      padding-bottom: 20 !important;
} */

.events-add-button-wrapper {
  margin-right: 2.6%;
  text-align: right !important;
}

.event-variant {
  margin-bottom: 50px !important;
}
.add-button-events-variant {
  background-color: var(--color-secondary) !important;
  width: 18.5rem;
}
.add-button-events-variant:hover {
  background-color: #fff !important;
  color: var(--color-secondary) !important;
  border: 1px solid var(--color-secondary) !important;
}
