.scrolling-wrapper-flexbox {
  background-color: #fafafa !important;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: nowrap;
  overflow-x: scroll;

  -webkit-overflow-scrolling: touch;
}

.card {
  flex: 0 0 auto;
  scrollbar-shadow-color: darkblue;
  cursor: pointer;
  background-color: #fafafa !important;
  border: none !important;
  height: 375px !important;
}

.cardHolder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.dash-news-card-content {
  height: 50px;
}

.dashboard-no-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;

  &-text {
    font-weight: bold;
    font-size: 16;
    margin-top: 10;
  }
}

.dash-search-news {
  margin-top: -38px;
  margin-left: 130px;
}

.dash-search-training {
  margin-top: -38px;
  margin-left: 130px;
}

.news {
  font-size: 30px;
}

.select-item {
  height: 100vh;
  width: 100%;
}

.tik {
  color: darkblue;
}

.trainin {
  margin-top: 20px;
  font-size: 30px;
}

.dashboard__top {
  margin-top: 2.188rem;
  margin-bottom: 3.438rem;
}
.dashboard__top--serach {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}
.see-more {
  float: right;
  font-size: 1.1rem;
  margin-top: 15px;
}
.search__title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.category__title {
  font-size: 1.5rem;
  font-weight: normal;
}
