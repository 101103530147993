.table__navigation {
  bottom: 0rem;
  position: inherit;
  width: 100%;
  padding-right: 20px !important;
  margin-top: 20px;
}
/* .table__navigation .MuiToolbar-root{
    z-index:1000;
} */
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #fff !important;
  border: 1px solid var(--color-hover) !important;
  background-color: var(--color-hover) !important;
}
.MuiPaginationItem-rounded {
  background-color: #f3f3f3 !important;
  border-color: #f3f3f3 !important;
  color: #829a9c !important;
  margin: 0 3px !important;
}
