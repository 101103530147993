.button--outlined {
  width: 8.175rem;
  height: 2.188rem;
  border-radius: 0.625rem !important;
  border: solid 1px var(--color-secondary) !important;
  font-family: SFProText-Bold !important;
  font-size: 1.1rem !important;
  color: var(--color-secondary) !important;
  text-transform: none !important;
}
.button--outlined:hover {
  background-color: var(--color-secondary) !important;
  color: #fff !important;
}
