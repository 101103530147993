.headerp {
  display: flex;
  margin: 15px;
}
.main2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main2 div {
  margin-top: 10px !important;
}
.login2 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.welcomemsg {
  font-size: 34px;
  margin-bottom: 60px;
}

.boxes {
  margin-right: 774px;
}
.f-passwordLabel {
  margin-right: 250px;
  color: darkgrey !important;
}
.emailverifacion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: large;
}

.f-linkLabel a {
  color: var(--color-primary);
  text-decoration: none;
  background-color: transparent;
}

.f-linkLabel a:hover {
  color: var(--color-hover);
  text-decoration: underline;
}
