.course-step svg {
  width: 2em;
  height: 2em;
  color: #fff;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
}
.course-step .MuiStepIcon-text {
  fill: #c4c4c4;
}
.course-step .MuiStepIcon-root.MuiStepIcon-active {
  color: #fff;
  border-color: var(--color-secondary);
}
.course-step .MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text {
  fill: var(--color-primary);
}

.course-step .MuiStepConnector-alternativeLabel {
  top: 20px;
  left: calc(-50% + 24px);
  right: calc(50% + 23px);
  position: absolute;
}
.course-step .MuiStepConnector-lineHorizontal {
  border-top-width: 8px;
  border-color: var(--color-primary);
}

.course-step .MuiStepLabel-label.MuiStepLabel-active {
  color: var(--color-primary);
}

.course-step .MuiStepLabel-label {
  color: #c4c4c4;
}

.course-step .MuiStepIcon-root.MuiStepIcon-completed {
  color: var(--color-active);
  border-color: var(--color-active);
}

.course-step .MuiStepLabel-label.MuiStepLabel-completed {
  color: var(--color-primary);
}

.add-course__buttons {
  display: flex;
  width: 90%;
  justify-content: center;
  margin: 6rem auto 0 auto;
  padding-bottom: 3rem;
}

.courses_modal--add-quiz > button {
  width: 7.875rem !important;
}
/*first step*/
.add-course__first-step {
  display: flex;
  width: 90%;
  margin: 0 auto;
}

.first-step--left,
.first-step--right {
  display: flex;
  width: 55%;
  flex-direction: column;
}

.first-step--right {
  align-items: center;
  width: 45%;
}

.first-step--right > div {
  width: 55%;
  margin: 0.5rem;
}

.first-step--left > div:nth-of-type(1),
.first-step--left > div:nth-of-type(2) {
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.first-step--left > div {
  margin-bottom: 1rem;
}

.upload-file__label-text {
  font-family: SFProDisplay-Regular;
  font-size: 1.1rem;
  color: var(--color-secondary);
  text-align: center;
  cursor: pointer;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.course-add__image--upload {
  width: 100%;
  margin: 0px;
  border-width: 0.156rem;
  margin-bottom: 1rem;
  padding: 3.469rem 0.875rem;
}
.course-add__image--upload > svg {
  width: 7rem;
  margin-right: 0rem;
}
/*second step*/
.add-course__second-step,
.add-course__third-step {
  width: 90%;
  margin: 0 auto;
}
.add-course__second-step--header {
  display: flex;
  justify-content: space-between;
}
.add-course__second-step--header p {
  font-family: SFProText-Regular;
  font-size: 1.2rem;
  color: var(--color-secondary);
}
.add-course__second-step--content {
  background-color: #fff;
  border-radius: 0.625rem;
  box-shadow: 0 0.375rem 0.625rem 0 rgb(191 191 191 / 11%),
    0 -0.375rem 0.625rem 0 rgb(191 191 191 / 12%);
  padding: 2.813rem 1.875rem 5rem;
  position: relative;
  margin-top: 1.563rem;
}

/*third step*/
.add-course__third-step--header p {
  align-items: center;
  display: flex;
}
.add-course__third-step .custom-file-upload {
  margin: 0;
  font-size: 1.1rem;
  width: 31%;
}
.add-course__third-step .custom-file-upload > svg {
  width: 1.5rem;
}
.add-course__third-step .nothingFound > div,
.add-course__second-step .nothingFound > div,
.add-course__fourth-step .nothingFound > div,
.course-details .nothingFound > div {
  width: 6.313rem;
  height: 5.938rem;
}
.add-course__second-step--content .nothingFound {
  margin-top: 0.5rem;
}
.add-course__second-step--content .nothingFound > p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.add-course__third-step--content {
  max-height: 15.9rem;
  overflow-y: auto;
  padding: 0.813rem 1.875rem 1rem;
}

/*course details*/
.course-details__content {
  padding-bottom: 2rem;
}
.slick-list {
  margin-top: 2rem !important;
}
.course-details__videos {
  padding: 0.5rem 3.5rem 1rem;
  border-right: 1px solid #039aa382;
  border-left: 1px solid #039aa382;
}
.course-details__videos > p {
  color: #667e80;
  font-size: 0.9rem;
  font-family: SFProDisplay-Regular;
  margin-bottom: 0.9rem;
}
.slick-next {
  fill: #d7d7d7 !important;
  width: 1.5em !important;
  height: 1.5em !important;
}
.course-details__videos-no-slider {
  border: none;
  width: 27.875rem;
  padding: 0.5rem 2.5rem 1rem;
}
.course-details__videos-content {
  padding-bottom: 1rem;
}
.course-details__videos-list {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
