select ::placeholder {
  font-size: medium;
}
/* .select[placeholder=text]::placeholder::after {
    visibility: hidden;
  } */

.articles-modal .MuiDialog-paper {
  max-width: 69.125rem;
  height: 48rem;
  overflow-y: auto;
}
.articles-modal .MuiDialog-paper {
  padding: 1.5rem 4rem;
}
.article-modal__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width:900px) {
  .article-modal__buttons {
    flex-wrap: wrap;
    flex-direction: column;
    
    .departments__modal--add {
      margin-top: 1.0rem;
      margin-left: 0 !important;
    }
  }
}
.article-modal__header {
  box-shadow: 0 0.438rem 0.625rem -0.188rem rgba(225, 225, 225, 0.25);
  padding-bottom: 0.5rem;
}
.article-modal__content {
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  overflow-y: auto;
}
.article-modal__content-input {
  display: flex;
  justify-content: space-between;
}
.article-modal__content-input > div {
  width: 100%;
  margin-bottom: 20px;
}

.lefttop {
  padding-right: 10px;
}
.righttop {
  padding-left: 10px;
}

.righttopmargin {
  margin-right: -15px;
}
.customq > div {
  width: 90%;
  margin: 1.2rem 1rem;
}

.tit {
  color: var(--color-secondary);
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.addquestionbutton {
  display: flex;
  justify-content: end;
}

.article-modal__uploaded-files > div {
  display: flex;
  flex-wrap: wrap;
}
.article-modal__uploaded-files {
  color: var(--color-secondary);
  font-family: SFProDisplay-Regular;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  margin-top: 20px;
}
.article-modal__uploaded-files span {
  color: rgba(102, 126, 128, 0.35);
  font-size: 1.1rem;
  margin-top: 1.3rem;
}
.rdw-editor-wrapper {
  /* background-color: #E9EEF0; */
  /* font-size: 1.2rem;
    font-family: SFProText-Regular;
    color: rgba(0, 0, 0, 0.679); */
}
.rdw-editor-toolbar {
  /* background: #D1E4E5; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.rdw-editor-wrapper {
  border-radius: 5px;
  border: 1px solid #00000080;
}
.rdw-editor-main {
  padding: 0 0.8rem;
  overflow-y: auto;
  height: 20rem;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.article-details {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  border-right: 3px solid var(--color-active);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.article-details__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.438rem 0.625rem -0.188rem rgba(225, 225, 225, 0.25);
  width: 100%;
  padding: 1rem 0;
}
.article-details__header > nav {
  width: fit-content;
}
.article-details__header > div {
  display: flex;
  align-items: center;
}
.article-details__header svg {
  cursor: pointer;
}
.article-details__header > div svg {
  fill: var(--color-secondary);
  width: 1.8em;
  height: 1.8em;
  cursor: pointer;
}
.article-details__header > div span {
  color: #c4c4c4;
  font-size: 1.2rem;
  font-family: SFProText-Regular;
  margin-left: 1.3rem;
}
.article-details__content > p {
  color: #667e80;
  font-size: 1rem;
  font-family: SFProText-Regular;
  padding: 0.5rem 0;
}
.article-details__content > p:nth-of-type(1),
.article-details__content > p:nth-of-type(2) {
  padding: 0.5rem 0;
}
.article-details__content > p span {
  margin-right: 1rem;
}
.article-details__content .article-markup > div:nth-of-type(1) {
  /* background-color: rgba(209, 228, 229, 0.2); */
  padding: 1.563rem 1.6rem;
  border-radius: 0.625rem;
  overflow-y: auto;
  /* height: 22rem; */
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  overflow-wrap: anywhere;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.article-details__content p span {
  font-family: SFProText-Semibold;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 2px solid var(--color-secondary);
  border-radius: 0.3rem;
  display: inline-block;
  padding: 9.5px 14px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-family: SFProDisplay-Regular;
  font-size: 1.3rem;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-file-upload > svg {
  width: 2rem;
  height: auto;
  margin-right: 1rem;
}
.article-details__files {
  display: flex;
  padding-bottom: 3rem;
  justify-content: center;
}

.article-details-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
}
.articles-scrollable-sidebar {
  width: 100%;
}
.articles-scrollable-sidebar h3 {
  color: var(--color-secondary);
  /* text-align: center; */
  font-size: 1.4rem;
  margin: 12px 0px;
}

// .articles-scrollable-sidebar > .MuiPaper-root ::-webkit-scrollbar {
//   width: 30px;
// }

.sidebar-article-wrapper {
  border-bottom: 1px solid #c4c4c4;
  padding: 12px 0;
  transition: all 0.5s;
  /* margin-top: 5px; */
  cursor: pointer;
}
.sidebar-article-wrapper:nth-of-type(1) {
  border-top: 1px solid #ccc;
}
.sidebar-article-wrapper:hover {
  background-color: #e5e5e560;
}
.sidebar-article-wrapper > p,
.sidebar-article-item {
  margin: 0;
  padding-left: 20px;
}

.sidebar-article-wrapper > p:nth-of-type(1) {
  color: #383838;
}
.sidebar-article-wrapper p:nth-of-type(2),
.sidebar-article-item {
  color: #c4c4c4;
}

.article-card-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.438rem 0.625rem -0.188rem rgba(225, 225, 225, 0.25);
  width: 100%;
  padding: 1rem 0;
}

.active-article {
  background-color: var(--color-active) !important;
}

.articleCard {
  max-height: 140px !important;
  position: relative !important;
  overflow: hidden !important;
}

.articleCard .article-content {
  position: relative;
  max-height: 50px;
  overflow: hidden;
  padding: 10px;
  margin-bottom: 20px;

  transition: max-height 0.15s ease-out;
}
.fade-out {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 60px;
  padding-top: 40px;
  background-image: linear-gradient(to bottom, transparent, white);
  cursor: pointer;
}

.active-article .fade-out {
  background-image: linear-gradient(
    to bottom,
    transparent,
    var(--color-active)
  ) !important;
}

.sidebar-article-wrapper:hover > .active-article .fade-out {
  background-image: linear-gradient(
    to bottom,
    transparent,
    var(--color-active)
  ) !important;
}

.sidebar-article-wrapper:hover > .fade-out {
  background-image: linear-gradient(to bottom, transparent, #e5e5e560);
}

.publish-article {
  float: right !important;
}

.delete-article-preview > button {
  float: left !important;
  background-color: red !important;
}
