.dashboard-slider-wrapper {
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: space-between !important;
  padding: 0 1rem;
}

.body__content2 {
  background-color: #fff;
}

.active-link {
  background-color: var(--color-secondary);
  color: #fff !important;
}

.active-link::after {
  /* content: " -"; */
}

.dashboard-links-wrapper {
  margin: 20px 0 100px;
}

.dashboard-links-list {
  list-style: none;
  display: flex;
  justify-content: center;
  text-align: center;
}

.dashboard-link {
  position: relative;
  width: 14rem;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 40px;
  margin: 7px;
  color: var(--color-secondary);
  font-weight: bold;
  transition: background-color 0.5s;
  border: 1px solid var(--color-secondary);
}

.dashboard-link:nth-of-type(1)::after,
.dashboard-link:nth-of-type(2)::after {
  content: "—";
  position: absolute !important;
  top: 5;
  right: -16px;
  color: var(--color-secondary);
}

.dashboard-link:hover {
  background-color: var(--color-secondary);
  color: #fff;
}

.media-card-head {
  .MuiTypography-h6 {
    line-height: 1.5 !important;
    font-size: 16px !important;
  }
}

.media-card-actions {
  position: absolute;
  width: 100%;
  bottom: 16px;
  right: 8px;
}
