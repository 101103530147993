.header {
  height: 5rem;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  right: 0;
  box-shadow: 0 0 20px rgb(89 102 122 / 10%);
  z-index: 1;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
}

.header__left > svg {
  height: 100%;
  width: auto;
}
.header__right {
  display: flex;
  align-items: center;
  width: 28.25rem;
  justify-content: space-evenly;
  max-height: 100%;
  margin-right: 2%;
}
.header__right .MuiBadge-anchorOriginTopRightCircle {
  top: 14%;
  right: 40% !important;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
}
.header__right .MuiBadge-root {
  padding-left: 17.5px;
  padding-right: 17.5px;
}
.header__right svg {
  cursor: pointer;
}
.header__right > svg {
  margin-left: 10.5px;
  margin-right: 10.5px;
}
.header__right-user {
  display: flex;
  align-items: center;
  font-family: SFProText-Regular;
  color: #a6b9ba;
  font-size: 1.1rem;
}
.header__right-user > span {
  margin-right: 0.75rem;
}
.header__right-user > strong {
  color: #455769d1;
}
.header__right-user-menu {
  position: relative;
}
.sidebar__menu-button {
  display: flex;
  height: 5rem;
  align-items: center;
  padding-left: 1.3rem;
}
.sidebar__menu-icon {
  width: auto;
  height: auto;
}
.sidebar__menu-button > svg {
  height: 100%;
  width: auto;
}
/*sidebar menu*/
.sidebar__menu-list {
  margin-top: 5.938rem !important;
  min-height: 300px;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
}
.sidebar__menu-list > a,
.sidebar__menu-list > .sidebar__menu-organization {
  padding-left: 30px;
}
.menu__list-text > span {
  font-family: SFProText-Regular !important;
  font-size: 1.2rem !important;
  color: #b4b4b4;
}
.menu__sublist-text > span {
  font-family: SFProText-Regular !important;
  color: #b4b4b4;
  font-size: 1rem !important;
}
.sidebar__menu-list > .MuiListItem-root.Mui-selected,
.sidebar__menu-list > .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(233, 238, 240, 0.62) !important;
  border-left: 10px solid var(--color-primary);
  padding-left: 20px;
}
.sidebar__menu-sublist > .MuiListItem-root.Mui-selected,
.sidebar__menu-sublist > .MuiListItem-root.Mui-selected:hover {
  background-color: rgba(233, 238, 240, 0.62) !important;
}
.sidebar__menu-list .MuiSvgIcon-root {
  fill: #b4b4b4;
}
.sidebar__menu-list > .MuiListItem-root.Mui-selected > .MuiSvgIcon-root,
.sidebar__menu-list > .MuiListItem-root.Mui-selected:hover > .MuiSvgIcon-root {
  fill: var(--color-primary);
}
.sidebar__menu-list .MuiListItem-root.Mui-selected > .menu__list-text > span,
.sidebar__menu-list
  .MuiListItem-root.Mui-selected:hover
  > .menu__list-text
  > span {
  color: var(--color-primary);
  font-family: SFProText-Semibold !important;
}
.menu__sublist--icon {
  min-width: 30px !important;
}
