.search {
  display: flex;
  align-items: center;
  width: 88%;
  margin-right: 1.0rem;
}

.search > input {
  width: 100%;
  /* max-width: 24.375rem;
    min-width: 18.375rem; */
  background-color: #fff;
  height: 2.8rem;
  border-radius: 25px 25px;
  border: solid 1px #f2f2f2;
  background-image: url("../../assets/images/search.svg");
  background-position: 1rem center;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  padding-left: 45px;
  outline: none;
  color: #535353;
  font-size: 18px;
}
.search > input::placeholder {
  color: #bbb4b4;
}
.search__button {
  height: 2.5rem;
  width: 6.875rem;
  border-radius: 0.438rem !important;
  border: solid 1.5px var(--color-primary) !important;
  color: var(--color-primary) !important;
  font-family: SFProDisplay-Regular !important;
  text-transform: unset !important;
  font-size: 1.1rem !important;
  margin-left: 1.563rem !important;
}
.search__button:hover {
  color: #fff !important;
  background-color: var(--color-primary) !important;
}
.searchnav {
  border: solid 2px #535353 !important;
  width: 75% !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important;
  border-radius: 16px 16px !important;
  border-color: #535353 !important;
  background-image: url("../../assets/images/search.svg");
  background-position: 0.313rem center;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  outline: hidden;
}
.searchnav > input::placeholder {
  color: #fff;
}
