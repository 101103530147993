.popup-menu {
  position: relative;
}
.popup-menu__content {
  width: 12.688rem;
  height: 22.938rem;
  flex-grow: 0;
  border-radius: 20px;
  box-shadow: 0 0.25rem 0.625rem 0 rgb(157 157 157 / 47%);
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  position: relative;
}
.popup-menu > svg {
  position: absolute;
  width: 3rem !important;
  height: auto !important;
  fill: #fff;
  top: -29px;
  right: 30%;
}
.popup-menu > svg > path {
  stroke: #3a3a3a;
  stroke-width: 1px;
}
.popup-menu__popper {
  top: 22px !important;
  left: -7px !important;
}
.popup-menu__content-button {
  text-transform: none !important;
  border: solid 1.5px var(--color-primary) !important;
  color: var(--color-primary) !important;
  font-family: SFProText-Regular !important;
  width: 90%;
}
.popup-menu__content-button:hover {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}
.popup-menu__user > p:nth-of-type(1) {
  font-family: SFProText-Semibold;
}
.popup-menu__user > p {
  padding-bottom: 0.313rem;
  overflow-wrap: anywhere;
}
.popup-menu__user {
  font-size: 0.8rem;
  color: #455769;
  font-family: SFProText-Regular;
  margin-bottom: 2.1rem;
}
.popup-menu__img {
  width: 57% !important;
  height: 5.5rem !important;
  margin-bottom: 1rem;
}
.popup-menu__img > img {
  width: 100%;
  height: auto;
}
.popup-menu__content--edit {
  position: absolute;
  top: 1rem;
  right: 0.8rem;
  width: 1.7rem !important;
  height: auto !important;
  cursor: pointer;
}
.popup-menu__avatar {
  cursor: pointer;
}
.popup-menu__avatar > img {
  width: 100%;
  height: auto;
}
.popup-menu__settings {
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  font-size: 0.9rem;
  cursor: pointer;
}
