*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
  font-size: 100%;
}
  @font-face {
    font-family: SFProDisplay-Bold;
    src: url("./assets/fonts/SFProDisplay-Bold.ttf");
  }
  
  @font-face {
    font-family: SFProDisplay-Semibold;
    src: url("./assets/fonts/SFProDisplay-Semibold.ttf");
  }
  @font-face {
    font-family: SFProDisplay-Regular;
    src: url("./assets/fonts/SFProDisplay-Regular.ttf");
  }
  @font-face {
    font-family: SFProText-Semibold;
    src: url("./assets/fonts/SFProText-Semibold.ttf");
  }
  @font-face {
    font-family: SFProText-Regular;
    src: url("./assets/fonts/SFProText-Regular.ttf");
  }
  @font-face {
    font-family: SFProText-Bold;
    src: url("./assets/fonts/SFProText-Bold.ttf");
  }
.wrapper{
  background-color: white;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}
.wrapper__page {
  display: flex;
  height: 100%;
  background-color: white;
}
.page__body{
  height: calc(100vh - 5rem);
  width:100%;
  margin-top: 5rem;
  padding: 0rem 0rem;
  /* padding: 2rem 3rem;  alte version */
  overflow-y: auto;
}
.body__content{
  background-color:#fafafa;
  overflow: auto;
  border-radius: 0.625rem;
  box-shadow: 0 0.375rem 0.625rem 0 rgba(191, 191, 191, 0.11), 0 -0.375rem 0.625rem 0 rgba(191, 191, 191, 0.12);
  width: 100%;
  height:100%;
  /* padding: 2.813rem 1.875rem; */
  padding: 2.013rem;
  position: relative;
}