.nothingFound {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}
.nothingFound > p {
  font-family: SFProText-Regular;
  font-size: 1.25rem;
  color: var(--color-secondary);
  margin-bottom: 2.188rem;
}
.nothingFound > div {
  width: 13.313rem;
  height: 12.938rem;
}
.nothingFound > div > img {
  width: 100%;
  height: 100%;
}
