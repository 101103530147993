.filePreview {
  background-color: #fff;
  box-shadow: 0 0.375rem 0.625rem 0 var(--color-active),
    0 -0.375rem 0.625rem 0 #039aa300;
  width: 5rem;
  height: 7rem;
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  position: relative;
  padding-bottom: 0.5rem;
  color: var(--color-secondary);
  font-family: SFProDisplay-Regular;
  font-size: 1.5rem;
}
.filePreview > p {
  font-size: 0.8rem;
  background-image: url("../../assets/images/document.png");
  background-size: cover;
  height: 3rem;
  width: 2.2rem;
  line-height: 4rem;
  color: #fff;
  text-align-last: center;
}
.filePreview > label,
.modalFilePreview > label {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.filePreview > svg,
.modalFilePreview > svg {
  position: absolute;
  top: -3px;
  right: -7px;
  fill: #ff0000b3;
  cursor: pointer;
}

.modalFilePreview {
  background-color: #fff;
  box-shadow: 0 0.375rem 0.625rem 0 var(--color-active),
    0 -0.375rem 0.625rem 0 #039aa300;
  width: 15rem;
  height: 3rem;
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  position: relative;
  color: var(--color-secondary);
  font-family: SFProDisplay-Regular;
  font-size: 1.5rem;
}
