.favourite-seite-all-wrapper {
     /* padding: 30px 5%; */
}

/* .favourites-articles-wrapper{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex: 450px;
      gap: 60px;
      grid-auto-rows: minmax(100px, auto);
} */
.favourites-articles-wrapper {
     display: grid;
     grid-template-columns: repeat(4, 1fr);
     grid-template-rows: 1fr 1fr;
     gap: 60px;
     grid-auto-columns: minmax(200px, auto);
}

.favourites-courses-wrapper {
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
     flex: 450px;
     gap: 60px;
     grid-auto-rows: minmax(100px, auto);
}

